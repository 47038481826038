import * as React from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/seo"
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';

const TilPage = ({ data }) => {
  const tils = data.allMarkdownRemark.nodes
  const recentTils = data.recentTils?.edges || [];

  return (
    <>
      <Navigation />
      <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <main className="lg:col-span-8 lg:col-start-1">
              <div className="mb-12">
                <h1 className="text-4xl text-gray-900">TILs</h1>
                <p className="text-gray-600 mt-4">
                  Things I've Learned, inspired by{" "}
                  <a 
                    href="https://til.simonwillison.net/" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="text-brand-600 hover:text-brand-700 hover:underline"
                  >
                    Simon Willison's TIL
                  </a>
                </p>
              </div>
              <div className="space-y-12">
                {tils.map(til => (
                  <article key={til.id} className="border-b border-gray-200 pb-8 last:border-b-0">
                    <div className="space-y-4">
                      <div className="text-sm text-gray-600">{til.frontmatter.date}</div>
                      <Link to={til.fields.slug} className="block group">
                        <h3 className="text-4xl text-brand-600 leading-tight tracking-tight">
                          {til.frontmatter.title}
                        </h3>
                      </Link>
                      {til.frontmatter.tags && (
                        <div className="flex flex-wrap gap-2">
                          {til.frontmatter.tags.map(tag => (
                            <Link
                              key={tag}
                              to={`/tags/${tag.toLowerCase().replace(/\s+/g, '-')}`}
                              className="text-sm text-gray-600 hover:text-brand-600"
                            >
                              #{tag}
                            </Link>
                          ))}
                        </div>
                      )}
                      <p className="text-gray-700 leading-relaxed">{til.excerpt}</p>
                      <Link 
                        to={til.fields.slug} 
                        className="inline-block text-brand-600 hover:text-brand-700 hover:underline"
                      >
                        Read More →
                      </Link>
                    </div>
                  </article>
                ))}
              </div>
            </main>

            {/* Sidebar */}
            <aside className="lg:col-span-3 lg:col-start-10">
              <div className="sticky top-8 space-y-8">
                {/* Recent TILs */}
                <div className="border-l-4 border-brand-200 pl-4">
                  <h2 className="text-xl font-bold text-gray-900 mb-4">Recent TILs</h2>
                  <div className="space-y-3">
                    {recentTils.map(({ node }) => (
                      <Link 
                        key={node.fields.slug}
                        to={node.fields.slug}
                        className="block text-gray-700 hover:text-brand-600"
                      >
                        {node.frontmatter.title}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TilPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/til/" } }
      sort: { fields: frontmatter___date, order: DESC }
      limit: 100
    ) {
      nodes {
        excerpt(pruneLength: 280)
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM Do, YYYY")
          title
          description
          tags
        }
        id
      }
    }
    recentTils: allMarkdownRemark(
      limit: 5
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/til/" } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
` 